@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Merriweather|Shrikhand');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Patua+One');

$primary: #FE7F2D;/* MAIN COLOR */
$secondary: #233D4D; /* SECONDARY COLOR */
$third: white;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

// html, body {
//     height: 100%;
// }

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

body {
    font-family: 'Merriweather', serif;
}

p {
    font-size: 1rem;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Shrikhand', cursive;
    @include fluid-type($min_width, $max_width, 1.2 * $min_font, 2 * $max_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $secondary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.banner {
    background-image: url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: 50%;
    height: 80vh;
    @media (max-width: 767px) {
        background-position: 60% 50%;
        height: 600px;
    }
    .heart {
        max-width: 120px;
        width: 100%;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg,
            rgba($primary, 0.8),
            rgba($secondary, 0.6)
        );
    }
    .overlay {
        background-color: rgba($primary, 0.8);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 40%;
        @media (max-width: 767px) {
            width: 100%;
            background-color: rgba($primary, 0.5);
        }
          .slogan {
            position: absolute;
            top: 50%;
            right: 0%;
            transform: translate(50%, -50%);
            color: $third;
            width: 110%;
            text-align: center;
            mix-blend-mode: screen;
            h1 {
                    color: $third;
            }
            @media (max-width: 767px) {
                left: 50%;
                right: initial;
                width: 80%;
                transform: translate(-50%, -50%);
            }
         }
    }
}

.bg2 {
    color: white;
}

.couple1 .img-couple {
    &::before {
        background: url(../img/couple1.jpg);
    }    
}

.couple2 .img-couple {
    &::before {
        background: url(../img/couple2.jpg);
    }    
}

.couple3 .img-couple  {
    &::before {
        background: url(../img/couple3.jpg);
    }    
}

@media (max-width: 767px) {
    .box-couple {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}

[class^=couple] {
    border: 1px solid white;
     .txt {
        padding: 0.2rem;
        h2 {
            line-height: 1.4;
        }
     }
     .img-couple {
        &::before {
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease-in-out;
            cursor: pointer;
        }
       height: 50vh;
       @media (max-width: 767px) {
        height: 75vh;
       }
       position: relative;
       overflow: hidden;
       &:hover::before {
        transform: scale(1.3) rotate(10deg);
       }
    }
}

.margTopBtm {
    margin-bottom: 5rem;
}

.titleBox {
    margin: 1rem 0;
}

.bg {
    background-color: $secondary;
    padding: 7rem 0;
    h1 {
        color: white;
    }
}

.intro {
    background-image: url(../img/abstract.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 8rem 0;
}

.txtBox {
    line-height: 2.2;
}

.btn {
    @include btn;
}

.col-xs-6 {
    @media (max-width: 500px) {
        width: 100%;
    }
}

.bg2 {
    background: $primary;
}

.form {
    background: white;
}

.hideOnSmall {
    @media (max-width: 767px) {
        display: none;
    }
    @media (max-width: 500px) {
        display: block;
    }
}